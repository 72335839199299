// import "./NavbarReact.scss";
// import logo from "../../Assets/images/cephei_logo.png";

// const NavbarReact = (props) => {

//     document.addEventListener("scroll", function () {
//         let header = document.querySelector("header");
//         header.classList.toggle("sticky", window.scrollY > 1);
//     })

//     window.onload = function () {
//         const navMenu = document.getElementById('nav-menu'),
//             toggleMenu = document.getElementById('toggle-menu'),
//             closeMenu = document.getElementById('close-menu');

//         toggleMenu.addEventListener("click", () => {
//             navMenu.classList.toggle("show__menu");
//         })

//         closeMenu.addEventListener("click", () => {
//             navMenu.classList.remove("show__menu");
//         })

//     }

//     // if (!toggleMenu) {
//     //     toggleMenu.addEventListener('click', () => {
//     //         navMenu.classList.toggle("show__menu");
//     //     });

//     // }

//     // if (!closeMenu) {

//     //     closeMenu.addEventListener('click', () => {
//     //         navMenu.classList.remove('show__menu');
//     //     });
//     // }

//     return (
//         <>
//             <header>
//                 <nav>
                
//                     <div className="logo">
// <a href="/"><img src={logo} alt="Logo" height="170"  width="200"  /></a></div>
//                     <div className="toggle__menu" id="toggle-menu">
//                         <i className='bx bxs-grid'></i>
//                     </div>
//                     <ul className="nav__list" id="nav-menu">
//                         <div className="close__menu" id="close-menu">
//                             <i className='bx bx-x'></i>
//                         </div>
//                         <li className="nav__item"><a href="/" className="nav__link">Home</a></li>
                        // <li className="nav__item dropdown"><a href="#" className="nav__link dropdown__link">Services <i className='bx bxs-chevron-down dropdown__icon'></i></a>
                        //     <div className="meghamenu">

                        //         <ul className="content">
                        //             <li className="meghamenu__item meghamenu__header">
                        //                 <a href="/service-data-related">Data Related Services</a>
                        //             </li>

                        //             <li className="meghamenu__item">
                        //                 <div className="menu__icon">
                        //                     <i className='bx bxs-coin-stack'></i>
                        //                 </div>
                        //                 <div className="meghamenu__link">
                        //                     <a href="/service-data-related">Data Warehouse Solution</a>
                        //                     <p>Using scalable data storage and retrieval to manage and analyze large volumes of your data </p>
                        //                 </div>
                        //             </li>
                        //             <li className="meghamenu__item">
                        //                 <div className="menu__icon">
                        //                     <i className='bx bxs-component'></i>
                        //                 </div>
                        //                 <div className="meghamenu__link">
                        //                     <a href="/service-data-related">Data Engineering Services</a>
                        //                     <p>Designing, building, testing, and maintaining the data architecture and infrastructure necessary to support our advanced data-driven solutions.</p>
                        //                 </div>
                        //             </li>
                        //             <li className="meghamenu__item">
                        //                 <div className="menu__icon">
                        //                     <i className='bx bx-folder-open'></i>
                        //                 </div>
                        //                 <div className="meghamenu__link">
                        //                     <a href="/service-data-related">Data Lake Implementation</a>
                        //                     <p>sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        //                 </div>
                        //             </li>
                        //             <li className="meghamenu__item">
                        //                 <div className="menu__icon">
                        //                     <i className='bx bx-stats'></i>
                        //                 </div>
                        //                 <div className="meghamenu__link">
                        //                     <a href="/service-data-related">Big Data Analytics</a>
                        //                     <p>Examining large and complex data sets to uncover hidden patterns, unknown correlations, market trends, customer preferences and other insights into your operations.</p>
                        //                 </div>
                        //             </li>
                        //             <li className="meghamenu__item">
                        //                 <div className="menu__icon">
                        //                     <i className='bx bx-shape-polygon'></i>
                        //                 </div>
                        //                 <div className="meghamenu__link">
                        //                     <a href="/service-data-related">Data Modelling</a>
                        //                     <p>Creating a conceptual representation of data structures and relationships that we then use to develop efficient databases.</p>
                        //                 </div>
                        //             </li>
                        //         </ul>
                        //         <ul className="content">
                        //             <li className="meghamenu__item meghamenu__header">
                        //                 <a href="/business-intelligence">
                        //                     Business Intelligence (BI) Services
                        //                 </a>
                        //             </li>
                        //             <li className="meghamenu__item">
                        //                 <div className="menu__icon">
                        //                     <i className='bx bx-line-chart'></i>
                        //                 </div>
                        //                 <div className="meghamenu__link">
                        //                     <a href="/business-intelligence">Hawkeye</a>
                        //                     <p>Using advanced algorithms to process large datasets to improve decision-making and drive growth of your business.</p>
                        //                 </div>
                        //             </li>
                        //             <li className="meghamenu__item">
                        //                 <div className="menu__icon">
                        //                     <i className='bx bx-bar-chart-square'></i>
                        //                 </div>
                        //                 <div className="meghamenu__link">
                        //                     <a href="/business-intelligence">Power BI</a>
                        //                     <p>sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        //                 </div>
                        //             </li>
                        //             {/* <li className="meghamenu__item">
                        //                 <div className="menu__icon">
                        //                     <i className='bx bxl-nodejs' ></i>
                        //                 </div>
                        //                 <div className="meghamenu__link">
                        //                     <a href="#">Lorem Ipsum</a>
                        //                     <p>sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        //                 </div>
                        //             </li> */}
                        //         </ul>
                        //         <ul className="content">
                        //             <li className="meghamenu__item meghamenu__header">
                        //                 <a href="/web-development">
                        //                     Website Development
                        //                 </a>
                        //             </li>
                        //             <li className="meghamenu__item">
                        //                 <div className="menu__icon">
                        //                     <i className='bx bx-code-alt'></i>
                        //                 </div>
                        //                 <div className="meghamenu__link">
                        //                     <a href="/web-development">Front-End Development</a>
                        //                     <p>Building responsive and interactive user interfaces using the latest web technologies, ensuring a seamless user experience across all devices. </p>
                        //                 </div>
                        //             </li>
                        //             <li className="meghamenu__item">
                        //                 <div className="menu__icon">
                        //                     <i className='bx bx-code-curly'></i>
                        //                 </div>
                        //                 <div className="meghamenu__link">
                        //                     <a href="/web-development">Back-End Development</a>
                        //                     <p>Building robust back-end systems to ensure a secure, scalable and efficient foundation for your website.</p>
                        //                 </div>
                        //             </li>
                        //             <li className="meghamenu__item">
                        //                 <div className="menu__icon">
                        //                     <i className='bx bx-globe'></i>
                        //                 </div>
                        //                 <div className="meghamenu__link">
                        //                     <a href="/web-development">Full Stack Development</a>
                        //                     <p>A holistic approach to web development that leverages both front-end and back-end technologies to create an intuitive and user-centric digital experience.</p>
                        //                 </div>
                        //             </li>
                        //         </ul>
                        //         <ul className="content">
                        //             <li className="meghamenu__item meghamenu__header">
                        //                 <a href="/consulting">Consulting</a>
                        //             </li>
                        //             <li className="meghamenu__item">
                        //                 <div className="menu__icon">
                        //                     <i className='bx bx-chat'></i>
                        //                 </div>
                        //                 <div className="meghamenu__link">
                        //                     <a href="/consulting">Consulting</a>
                        //                     <p>Expert guidance that can help you make informed decisions about your technology investments and ensure that your business stays competitive in today's rapidly evolving digital landscape.</p>
                        //                 </div>
                        //             </li>
                        //         </ul>

                        //     </div>
                        // </li>
//                         <li className="nav__item"><a href="/about-us" className="nav__link">About Us</a></li>
//                         <li className="nav__item"><a href="/Careers" className="nav__link">Careers</a></li>
//                         <li className="nav__item"><a href="/contact" className="nav__link">Contact</a></li>
//                     </ul>
//                 </nav>
//             </header>
//         </>
//     );
// }

// export default NavbarReact;

import React, { useState } from 'react';
import logo from '../../Assets/images/cephei_logo.png';
import './NavbarReact.scss';

const NavbarReact = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleScroll = () => {
    const header = document.querySelector('header');
    header.classList.toggle('sticky', window.scrollY > 1);
  };

  document.addEventListener('scroll', handleScroll);

  return (
    <header>
      <nav>
        <div className="logo">
          <a href="/">
            <img src={logo} alt="Logo" height="170" width="200" />
          </a>
        </div>
        <div className="toggle__menu" onClick={toggleMenu}>
          <i className={isMenuOpen ? 'bx bx-x' : 'bx bxs-grid'}></i>
        </div>
        <ul className={`nav__list ${isMenuOpen ? 'show__menu' : ''}`}>
          <div className="close__menu" onClick={closeMenu}>
            <i className="bx bx-x"></i>
          </div>
          <li className="nav__item">
            <a href="/" className="nav__link">
              Home
            </a>
          </li>
          <li className="nav__item dropdown">
            <div className="nav__link dropdown__link">
              Services <i className="bx bxs-chevron-down dropdown__icon"></i>
            </div>
            <div className="meghamenu">

                                <ul className="content">
                                    <li className="meghamenu__item meghamenu__header">
                                        <a href="/service-data-related">Data Related Services</a>
                                    </li>

                                    <li className="meghamenu__item">
                                        <div className="menu__icon">
                                            <i className='bx bxs-coin-stack'></i>
                                        </div>
                                        <div className="meghamenu__link">
                                            <a href="/service-data-related">Data Warehouse Solution</a>
                                            <p>Using scalable data storage and retrieval to manage and analyze large volumes of your data </p>
                                        </div>
                                    </li>
                                    <li className="meghamenu__item">
                                        <div className="menu__icon">
                                            <i className='bx bxs-component'></i>
                                        </div>
                                        <div className="meghamenu__link">
                                            <a href="/service-data-related">Data Engineering Services</a>
                                            <p>Designing, building, testing, and maintaining the data architecture and infrastructure necessary to support our advanced data-driven solutions.</p>
                                        </div>
                                    </li>
                                    <li className="meghamenu__item">
                                        <div className="menu__icon">
                                            <i className='bx bx-folder-open'></i>
                                        </div>
                                        <div className="meghamenu__link">
                                            <a href="/service-data-related">Data Lake Implementation</a>
                                            <p>sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                        </div>
                                    </li>
                                    <li className="meghamenu__item">
                                        <div className="menu__icon">
                                            <i className='bx bx-stats'></i>
                                        </div>
                                        <div className="meghamenu__link">
                                            <a href="/service-data-related">Big Data Analytics</a>
                                            <p>Examining large and complex data sets to uncover hidden patterns, unknown correlations, market trends, customer preferences and other insights into your operations.</p>
                                        </div>
                                    </li>
                                    <li className="meghamenu__item">
                                        <div className="menu__icon">
                                            <i className='bx bx-shape-polygon'></i>
                                        </div>
                                        <div className="meghamenu__link">
                                            <a href="/service-data-related">Data Modelling</a>
                                            <p>Creating a conceptual representation of data structures and relationships that we then use to develop efficient databases.</p>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="content">
                                    <li className="meghamenu__item meghamenu__header">
                                        <a href="/business-intelligence">
                                            Business Intelligence (BI) Services
                                        </a>
                                    </li>
                                    {/* <li className="meghamenu__item">
                                        <div className="menu__icon">
                                            <i className='bx bx-line-chart'></i>
                                        </div>
                                        <div className="meghamenu__link">
                                            <a href="/business-intelligence">Hawkeye</a>
                                            <p>Using advanced algorithms to process large datasets to improve decision-making and drive growth of your business.</p>
                                        </div>
                                    </li> */}
                                    <li className="meghamenu__item">
                                        <div className="menu__icon">
                                            <i className='bx bx-bar-chart-square'></i>
                                        </div>
                                        <div className="meghamenu__link">
                                            <a href="/business-intelligence">Power BI</a>
                                            <p>sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                        </div>
                                    </li>
                                    {/* <li className="meghamenu__item">
                                        <div className="menu__icon">
                                            <i className='bx bxl-nodejs' ></i>
                                        </div>
                                        <div className="meghamenu__link">
                                            <a href="#">Lorem Ipsum</a>
                                            <p>sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                        </div>
                                    </li> */}
                                </ul>
                                <ul className="content">
                                    <li className="meghamenu__item meghamenu__header">
                                        <a href="/web-development">
                                            Website Development
                                        </a>
                                    </li>
                                    <li className="meghamenu__item">
                                        <div className="menu__icon">
                                            <i className='bx bx-code-alt'></i>
                                        </div>
                                        <div className="meghamenu__link">
                                            <a href="/web-development">Front-End Development</a>
                                            <p>Building responsive and interactive user interfaces using the latest web technologies, ensuring a seamless user experience across all devices. </p>
                                        </div>
                                    </li>
                                    <li className="meghamenu__item">
                                        <div className="menu__icon">
                                            <i className='bx bx-code-curly'></i>
                                        </div>
                                        <div className="meghamenu__link">
                                            <a href="/web-development">Back-End Development</a>
                                            <p>Building robust back-end systems to ensure a secure, scalable and efficient foundation for your website.</p>
                                        </div>
                                    </li>
                                    <li className="meghamenu__item">
                                        <div className="menu__icon">
                                            <i className='bx bx-globe'></i>
                                        </div>
                                        <div className="meghamenu__link">
                                            <a href="/web-development">Full Stack Development</a>
                                            <p>A holistic approach to web development that leverages both front-end and back-end technologies to create an intuitive and user-centric digital experience.</p>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="content">
                                    <li className="meghamenu__item meghamenu__header">
                                        <a href="/consulting">Consulting</a>
                                    </li>
                                    <li className="meghamenu__item">
                                        <div className="menu__icon">
                                            <i className='bx bx-chat'></i>
                                        </div>
                                        <div className="meghamenu__link">
                                            <a href="/consulting">Consulting</a>
                                            <p>Expert guidance that can help you make informed decisions about your technology investments and ensure that your business stays competitive in today's rapidly evolving digital landscape.</p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
          </li>
          <li className="nav__item">
            <a href="/about-us" className="nav__link">
              About Us
            </a>
          </li>
          <li className="nav__item">
            <a href="/Careers" className="nav__link">
              Careers
            </a>
          </li>
          <li className="nav__item">
            <a href="/contact" className="nav__link">
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default NavbarReact;
