import {  useState, useRef } from "react";
import axios from "axios";
import "./CareersForm.js";
import { ClipLoader } from 'react-spinners';
// import ReCAPTCHA from "react-google-recaptcha";

const CareersForm = (props) => {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
   const [Company, setCompany] = useState("");
  // const [domain, setdomain] = useState("");
  const [Message, setMessage] = useState("");
  const [File, setFile] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef(null);

  // const captchaRef = useRef();

  const handleOnSubmit = (e) => {
    setIsSubmitting(true);
    if (!Name || !Email  || !Message) {
      e.preventDefault();
      setIsSubmitting(false);
      alert("Please fill all the field");
      return;
    }

     e.preventDefault();

     

    const formData = new FormData();
    formData.append('name', Name);
    formData.append('email', Email);
    formData.append('domain', selectedDomain);
    formData.append('remarks', Message);
    formData.append('file', File);

      axios.post(process.env.REACT_APP_serverURL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
        console.log(res);
        alert("Your message has been sent successfully");
        setName("");
        setEmail("");
        setSelectedDomain([]);
        setMessage("");
        setFile(null);
        fileInputRef.current.value = '';      
        setIsSubmitting(false);        
      })
      .catch(err => {
        console.log(err);
        alert("Something went wrong");
        setIsSubmitting(true);
      })

    // const token = captchaRef.current.getValue();
    // console.log(token);
    // captchaRef.current.reset();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="contact-us">
      <div className="contact-container">
        <div className="contact-content">
          <div className="contact-left-side">
            <div className="address details">
              <i className="bx bx-map"></i>
              <div className="topic">Ahmedabad, India</div>
            </div>
            <div className="phone details">
              <i className="bx bx-phone"></i>
              <div className="topic">+91-90331-03903</div>
            </div>
            <div className="email details">
              <i className="bx bx-envelope"></i>
              <div className="topic">connect@cepheiinfotech.com</div>
            </div>
          </div>
          <div className="right-side">
            <div className="topic-text">Send Us A Message</div>
            <form  onSubmit={handleOnSubmit}>
              <div className="input-box">
                <input
                  type="text"
                  value={Name}
                  placeholder="Enter Your Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="input-box">
                <input
                  type="text"
                  value={Email}
                  placeholder="Enter Your Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="col-12" style={{ color: "#212529" }}>
                <label htmlFor="domainRequired" className="form-label">
                  Which domain(s) Do You Interested?
                </label>
                <div className="form-check">
                  <div className="form-check form-check-inline">
                    {/* <input className="form-check-input" type="checkbox" id="dataEngineeringCheck" value="option1" /> */}
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="dataEngineeringCheck"
                      value="Data Engineering"
                      checked={selectedDomain.includes("Data Engineering")}
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedDomain([
                              ...selectedDomain,
                              e.target.value,
                            ])
                          : setSelectedDomain(
                              selectedDomain.filter(
                                (domain) => domain !== e.target.value
                              )
                            )
                      }
                    />
                    <label className="form-check-label" htmlFor="inlineCheckbox1">
                    Data Engineering
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    {/* <input className="form-check-input" type="checkbox" id="webDevelopementCheck" value="option2" /> */}
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="webDevelopementCheck"
                      value="Full Stack Development"
                      checked={selectedDomain.includes("Full Stack Development")}
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedDomain([
                              ...selectedDomain,
                              e.target.value,
                            ])
                          : setSelectedDomain(
                              selectedDomain.filter(
                                (domain) => domain !== e.target.value
                              )
                            )
                      }
                    />
                    <label className="form-check-label" htmlFor="inlineCheckbox2">
                      Full Stack Development
                    </label>
                  </div>
                </div>
              </div>
              <div className="input-box message-box">
                <textarea
                  placeholder="Describe Your Thoughts"
                  value={Message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="formFile" className="form-label">
                  Upload Resume
                </label>
                <input className="form-control" type="file" id="formFile" ref={fileInputRef} onChange={handleFileChange} />
              </div>
              <div className="button">
                <input type="submit" value="Send Now" className="button" />
              </div>
            </form>
            {isSubmitting && (
        <div>
          <ClipLoader size={50} color="#123abc" />
          <p>Submitting form...</p>
        </div>
      )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersForm;
