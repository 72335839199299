import { useEffect } from "react";

import "./BusinessIntelligence.scss";
import img12 from "../../Assets/images/img/web-dev.gif";
// import blobSolidRedPink from "../../Assets/images/img/blob-solid-4-redpink.svg";
// import blobDottedGreenBlue from "../../Assets/images/img/blob-dotted-5-greenblue-00B8A7.svg";
import blobHaikeiTopRight from "../../Assets/images/img/blob-haikei-2-purple.svg";
// import blobHaikeiLeftBottom from "../../Assets/images/img/blob-haikei-3-blue-left-bottom.svg";

const BusinessIntelligence = () => {

    useEffect(() => {
        document.title = "Business Intelligence | Cephei Infotech";
    }, []);

    return (
        <div className="bussiness-intelligence">
            <section className="web-dev-hero-section web-dev-row web-dev-container">
                <div className="web-dev-hero-text">
                    <h1>
                        <span>
                        Business Intelligence
                        </span>
                    </h1>
                    <p>Our Business Intelligence services empower organizations with valuable insights and data-driven decision-making. From data gathering and analysis to visualization and reporting, we leverage cutting-edge tools and techniques to transform raw data into actionable intelligence. Trust us to provide comprehensive Business Intelligence solutions that drive growth, optimize operations, and unlock your business's full potential.</p>
                    <a href="/contact" className="web-dev-hero-btn">Let's Connect</a>
                </div>
                <div className="web-dev-row web-dev-image__fade">
                    <img src={img12} alt=''></img>
                </div>
            </section>

            <img src={blobHaikeiTopRight} className="blob-haikei-top-right" alt=""></img>
            {/* <img src={blobHaikeiLeftBottom} className="blob-haikei-top-right" alt=""></img> */}
            {/* <div className="web-dev-quote-container">
                <img src={blobDottedGreenBlue} className="blob-dotted-left" alt=""></img>
                <div className="web-dev-quote-section">
                    <img src={blobSolidRedPink} className="blob-inside-quote-section" alt=""></img>
                    <h2>Lorem ipsum dolor</h2>
                    <div className="web-dev-quote-container-quote">
                        <p>

                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.

                        </p>
                    </div>
                </div>
            </div> */}

            <div className="service-container">
                <div className="row">
                    <div className="col-md-3"></div> {/* Added empty column for centering */}
                    <div className="col-md-6"> {/* Adjusted column width */}
                        <div className="main">
                            <div className="service">
                                <div className="service-logo">
                                    <i className='bx bx-code-curly'></i>
                                </div>
                                <h4>Power BI</h4>
                                <p className="content">Harness the power of data with Power BI, a comprehensive business intelligence platform. With intuitive data visualization, interactive dashboards, and advanced analytics capabilities, Power BI empowers businesses to make informed decisions, uncover insights, and drive growth. Leverage Power BI services to transform raw data into actionable intelligence and gain a competitive edge in today's data-driven world.</p>
                                {/* <a href="#" className="hero__button">Learn More</a> */}
                            </div>
                            <div className="shadow-one"></div>
                            <div className="shadow-two"></div>
                        </div>
                    </div>
                    <div className="col-md-3"></div> {/* Added empty column for centering */}
                </div>
            </div>
            {/* <div className="web-dev-container">
                <div className="web-developement-header">
                    <div className="web-developement-header-left">
                        <h1>Web Developement</h1>
                    </div>
                    <div className="web-developement-right-div">
                        <img src={BusinessIntelligenceImage} />
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default BusinessIntelligence;