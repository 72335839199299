import { useEffect } from "react";

import "./DataRelatedService.scss";
import img12 from "../../Assets/images/img/web-dev.gif";
import blobSolidRedPink from "../../Assets/images/img/blob-solid-4-redpink.svg";
import blobDottedGreenBlue from "../../Assets/images/img/blob-dotted-5-greenblue-00B8A7.svg";
import blobHaikeiTopRight from "../../Assets/images/img/blob-haikei-2-purple.svg";
import blobHaikeiLeftBottom from "../../Assets/images/img/blob-haikei-3-blue-left-bottom.svg";

const DataRelatedService = () => {

    useEffect(() => {
        document.title = "Data Related Service | Cephei Infotech";
    }, []);

    return (
        <div className="data-related-service">
            <section className="web-dev-hero-section web-dev-row web-dev-container">
                <div className="web-dev-hero-text">
                    <h1>
                        <span>
                        Data Related Service
                        </span>
                    </h1>
                    <p>Unlock the true potential of your data with our comprehensive data-related services. From data management and cleansing to data analysis and visualization, we offer end-to-end solutions that enable you to derive valuable insights and make informed decisions. Trust us to optimize your data processes and empower your business with the knowledge to drive growth and success.</p>
                    <a href="/contact" className="web-dev-hero-btn">Let's Connect</a>
                </div>
                <div className="web-dev-row web-dev-image__fade">
                    <img src={img12} alt=''></img>
                </div>
            </section>

            <img src={blobHaikeiTopRight} className="blob-haikei-top-right" alt=""></img>
            {/* <img src={blobHaikeiLeftBottom} className="blob-haikei-top-right" alt=""></img> */}
            {/* <div className="web-dev-quote-container">
                <img src={blobDottedGreenBlue} className="blob-dotted-left" alt=""></img>
                <div className="web-dev-quote-section">
                    <img src={blobSolidRedPink} className="blob-inside-quote-section" alt=""></img>
                    <h2>Lorem ipsum dolor</h2>
                    <div className="web-dev-quote-container-quote">
                        <p>

                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.

                        </p>
                    </div>
                </div>
            </div> */}

            <div className="service-container-data">
                <div className="row">
                    <div className="col-md-4">
                        <div className="main">
                            <div className="service">
                                <div className="service-logo">
                                    <i className='bx bx-code-alt'></i>
                                </div>
                                <h4>Data Warehouse Solution</h4>
                                <p className="content">Streamline your data management with our robust data warehouse solution. We offer scalable architecture, efficient data integration, and advanced analytics capabilities, enabling you to centralize and analyze your data for valuable insights. Trust us to design, implement, and optimize your data warehouse, empowering your business with a solid foundation for data-driven decision-making.</p>
                                {/* <a href="/contact" className="hero__button">Let's Connect</a> */}
                            </div>
                            <div className="shadow-one"></div>
                            <div className="shadow-two"></div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="main">
                            <div className="service">
                                <div className="service-logo">
                                    <i className='bx bx-code-curly'></i>
                                </div>
                                <h4>Data Engineering Services</h4>
                                <p className="content">Our data engineering services provide end-to-end solutions for efficient data processing and transformation. From data ingestion and integration to data pipeline design and optimization, we enable you to extract value from your data assets. Trust us to architect, build, and automate data workflows, empowering your business with reliable and scalable data infrastructure.</p>
                                {/* <a href="/contact" className="hero__button">Let's Connect</a> */}
                            </div>
                            <div className="shadow-one"></div>
                            <div className="shadow-two"></div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="main">
                            <div className="service">
                                <div className="service-logo">
                                    <i className='bx bx-globe'></i>
                                </div>
                                <h4>Data Lake Implementation</h4>
                                <p className="content content-space">Accelerate your data-driven insights with our expert data lake implementation services. We design and deploy scalable and cost-effective data lake architectures, enabling efficient storage, processing, and analysis of large volumes of diverse data. Leverage our expertise to unlock the full potential of your data assets and gain valuable business insights.</p>
                                {/* <a href="/contact" className="hero__button">Let's Connect</a> */}
                            </div>
                            <div className="shadow-one"></div>
                            <div className="shadow-two"></div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="service-container-data">
                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-4">
                        <div className="main">
                            <div className="service">
                                <div className="service-logo">
                                    <i className='bx bx-code-alt'></i>
                                </div>
                                <h4>Big Data Analytics</h4>
                                <p className="content">Harness the power of big data with our advanced analytics solutions. We employ cutting-edge technologies and techniques to process and analyze massive datasets, uncovering valuable patterns, trends, and insights. Transform your business with data-driven decision-making and stay ahead of the competition with our comprehensive big data analytics services.</p>
                                {/* <a href="/contact" className="hero__button">Let's Connect</a> */}
                            </div>
                            <div className="shadow-one"></div>
                            <div className="shadow-two"></div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="main">
                            <div className="service">
                                <div className="service-logo">
                                    <i className='bx bx-code-curly'></i>
                                </div>
                                <h4>Data Modelling</h4>
                                <p className="content">Our data modeling services provide a solid foundation for effective data organization and analysis. We employ industry-leading techniques to design logical and physical data models that capture the structure and relationships within your data. Trust us to optimize your data architecture, enhancing data quality, usability, and scalability for informed decision-making.</p>
                                {/* <a href="/contact" className="hero__button">Let's Connect</a> */}
                            </div>
                            <div className="shadow-one"></div>
                            <div className="shadow-two"></div>
                        </div>
                    </div>

                    {/* <div className="col-md-4">
                        <div className="main">
                            <div className="service">
                                <div className="service-logo">
                                    <i className='bx bx-globe'></i>
                                </div>
                                <h4>Data Lake Implementation</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus nec commodo diam. Aenean eu commodo elit. Etiam vel magna congue elit eleifend tincidunt. Sed a fringilla magna, non pellentesque ex. Nullam faucibus tellus orci.</p>
                                <a href="#" className="hero__button">Let's Connect</a>
                            </div>
                            <div className="shadow-one"></div>
                            <div className="shadow-two"></div>
                        </div>
                    </div> */}

                </div>
            </div>
            {/* <div className="web-dev-container">
                <div className="web-developement-header">
                    <div className="web-developement-header-left">
                        <h1>Web Developement</h1>
                    </div>
                    <div className="web-developement-right-div">
                        <img src={DataRelatedServiceImage} />
                    </div>
                </div>
            </div> */}
        </div>
    );

}

export default DataRelatedService;