import "./AboutUs.scss";
import img12 from "../../Assets/images/img/web-dev.gif";

const AboutUs = (props) => {
  return (
    <div className="about-us">
      <div className="top-hero-header">
        <section className="about-us-hero-section about-us-row about-us-container">
          <div className="about-us-hero-text">
            <div className="banner-pattern">
              <div className="pattern_inner">
                <span></span>
                <div className="line line1"></div>
                <div className="line line2"></div>
                <div className="line line3"></div>
              </div>
            </div>
            <h1>
              <span>Know Us</span>
            </h1>
            {/* <p>Founded in Ahmedabad, Cephei Infotech was born from the passion and determination of
founder Raj Shah- a data expert with years of experience in the field. Raj's entrepreneurial
journey began with a simple yet significant realization, an opportunity to solve a common
problem faced by many companies. After a client shared their difficulties with data segregation
and interpretation, he took it upon himself to find an efficient solution, culminating in what we are
today. Our first client, Eris Pharma, was impressed with our services, and since then, we have
been growing as a startup. Our mission is to empower businesses by providing innovative
solutions that streamline their data, enhance connectivity and drive growth. With our founder's
experience and passion for utilizing data to drive success, we strive to deliver exceptional
services to our clients.</p> */}
            <p>
              Cephei Infotech, founded in Ahmedabad, is a data-driven technology
              company driven by founder Raj Shah's passion and expertise. With a
              focus on solving common data challenges, we provide innovative
              solutions to streamline data, enhance connectivity, and drive
              business growth. With a strong foundation and a mission to empower
              businesses, we deliver exceptional services backed by our
              founder's experience and dedication to success.
            </p>
            <a href="/contact" className="about-us-hero-btn">
              Let's Connect
            </a>
          </div>
          <div className="about-us-hero-text">
          <div className="web-dev-row web-dev-image__fade">
          <img src={img12} alt=""></img>
        </div>
          </div>
        </section>
      </div>

      <div className="processFlow">
        <div className="processFlow-container">
          <div className="containerInner">
            <div className="header text-center">
              <h1>
                PROCESS <b>FLOW</b>
              </h1>
            </div>
            <div className="processMain">
              <div className="process process1 col-md-2 col-sm-2">
                <div className="icon">
                  <span className="pe-7s-pin">
                    <i className="bx bx-notepad"></i>
                  </span>
                </div>
                <div className="name">UNDERSTAND</div>
              </div>
              {/* <div className='.processafterline'></div> */}
              <div className="process process2 col-md-2 col-sm-2">
                <div className="icon">
                  <span className="pe-7s-display2">
                    <i className="bx bx-brain"></i>
                  </span>
                </div>
                <div className="name">DEFINE MVP &amp; BETA</div>
              </div>
              <div className="process process3 col-md-2 col-sm-2">
                <div className="icon">
                  <span className="pe-7s-pen">
                    <i className="bx bx-sitemap"></i>
                  </span>
                </div>
                <div className="name">DESIGN</div>
              </div>
              <div className="process process4 col-md-2 col-sm-2">
                <div className="icon">
                  <span className="pe-7s-config">
                    <i className="bx bx-shape-circle"></i>
                  </span>
                </div>
                <div className="name">DEVELOP</div>
              </div>
              <div className="process process4 col-md-2 col-sm-2 process5">
                <div className="icon">
                  <span className="pe-7s-paper-plane">
                    <i className="bx bx-paper-plane"></i>
                  </span>
                </div>
                <div className="name">LAUNCH</div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mis-vis">
        <div className="mis-vis-container cmr-tw-adv">
          <div className="mlr-auto">
            <div >
              <h2 className='mis-vis-title'>The 6Vs (Volume, Value, Variety, Velocity, Veracity, and Variability) are the six critical factors
that help our company stay ahead in the industry and deliver exceptional solutions to our clients.</h2>
            </div>
            <div className="mis-vis-row">
              <div className="col-sm-6  mis-vis-item ">
                <div className="tw-plate tw-two">
                  <div className="cmr-tip-des">
                    <div className="spriteOne"></div>
                    <p className="cmr-point mt-3">
                      <span style={{ backgroundColor: "#a9f3c0" }}>Volume</span>
                    </p>
                  </div>
                  <div className="slide-plate">
                    <div className="spriteOne slide-number slide-act-one">

                    </div><div className="slide-detail">
                      <p>
                      Our company is equipped to handle large amounts of data, providing comprehensive
data-related services that are scalable to meet the ever-increasing needs of our clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6  mis-vis-item">
                <div className="tw-plate tw-three">
                  <div className="cmr-tip-des">
                    <div className="spriteOne"></div>
                    <p className="cmr-point mt-3">
                      <span>Value</span>
                    </p>
                  </div>
                  <div className="slide-plate">
                    <div className="spriteOne slide-number slide-act-one"></div>
                    <div className="slide-detail">
                      <p>We understand the importance of data and strive to deliver valuable insights that drive
growth and success for our clients. Our business intelligence solutions, data analytics services,
and consulting offerings are designed to provide valuable information that can be leveraged to
make informed decisions.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mis-vis-item ">
                <div className="tw-plate tw-two">
                  <div className="cmr-tip-des">
                    <div className="spriteOne"></div>
                    <p className="cmr-point mt-3" >
                      <span style={{ backgroundColor: "#e7e2ff" }}>Variety</span>
                    </p>
                  </div>
                  <div className="slide-plate">
                    <div className="spriteOne slide-number slide-act-one">

                    </div><div className="slide-detail">
                      <p>
                      Our company offers a diverse range of services to meet the specific needs of our clients.
From data warehousing to website development, our offerings are designed to address the needs
of organizations of all sizes and across industries.
</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6  mis-vis-item">
                <div className="tw-plate tw-three">
                  <div className="cmr-tip-des">
                    <div className="spriteOne"></div>
                    <p className="cmr-point mt-3">
                      <span style={{ backgroundColor: "#a9f3c0" }}>Velocity</span>
                    </p>
                  </div>
                  <div className="slide-plate">
                    <div className="spriteOne slide-number slide-act-one"></div>
                    <div className="slide-detail">
                      <p>Time is of the essence, and we recognize the importance of delivering solutions
quickly to stay ahead of the competition. Our services are designed to meet the fast-paced needs
of businesses, allowing them to make quick decisions and stay ahead of the curve.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mis-vis-item ">
                <div className="tw-plate tw-two">
                  <div className="cmr-tip-des">
                    <div className="spriteOne"></div>
                    <p className="cmr-point mt-3">
                      <span>Veracity</span>
                    </p>
                  </div>
                  <div className="slide-plate">
                    <div className="spriteOne slide-number slide-act-one">

                    </div><div className="slide-detail">
                      <p>
                      The quality and accuracy of data is paramount to making informed decisions. Our data
engineering and analytics services are designed to ensure the veracity of data, allowing
organizations to rely on the information they receive.
</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6  mis-vis-item">
                <div className="tw-plate tw-three">
                  <div className="cmr-tip-des">
                    <div className="spriteOne"></div>
                    <p className="cmr-point mt-3">
                      <span style={{ backgroundColor: "#e7e2ff" }}>Variability</span>
                    </p>
                  </div>
                  <div className="slide-plate">
                    <div className="spriteOne slide-number slide-act-one"></div>
                    <div className="slide-detail">
                      <p>We understand that businesses change and evolve over time, and our solutions are
designed to accommodate variability. Our services are flexible and customizable to meet the
changing needs of our clients, ensuring that they always have access to the information and
solutions they need to succeed.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="vContainer">
        <div>
          <h2 className="mis-vis-title">
            The 6Vs are the six critical factors that help our company stay
            ahead in the industry and deliver exceptional solutions to our
            clients.
          </h2>
        </div>
        <div>
          <div className="row mis-vis ">
            <div className="col col1">
              <div className="title">
                <span style={{ backgroundColor: "#a9f3c0" }}>Volume</span>
              </div>

              <div className="slide-plate">
                {/* <div className="spriteOne slide-number slide-act-one">

                    </div> */}
                <div className="slide-detail">
                  <p>
                    Our company is equipped to handle large amounts of data,
                    providing comprehensive data-related services that are
                    scalable to meet the ever-increasing needs of our clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="col col2">
              <div className="title" style={{ backgroundColor: "#fbbfce" }}>
                <span>Value</span>
              </div>

              <div className="slide-plate">
                <div className="spriteOne slide-number slide-act-one"></div>
                <div className="slide-detail">
                  <p>
                    We understand the importance of data and strive to deliver
                    valuable insights that drive growth and success for our
                    clients. Our business intelligence solutions, data analytics
                    services, and consulting offerings are designed to provide
                    valuable information that can be leveraged to make informed
                    decisions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col col3">
              <div className="title" style={{ backgroundColor: "#e7e2ff" }}>
                <span>Variety</span>
              </div>

              <div className="slide-plate">
                <div className="spriteOne slide-number slide-act-one"></div>
                <div className="slide-detail">
                  <p>
                    Our company offers a diverse range of services to meet the
                    specific needs of our clients. From data warehousing to
                    website development, our offerings are designed to address
                    the needs of organizations of all sizes and across
                    industries.
                  </p>
                </div>
              </div>
            </div>
            {/* </div>
      <div className="row"> */}
            <div className="col col4">
              <div className="title">
                <span style={{ backgroundColor: "#a9f3c0" }}>Velocity</span>
              </div>

              <div className="slide-plate">
                <div className="spriteOne slide-number slide-act-one"></div>
                <div className="slide-detail">
                  <p>
                    Time is of the essence, and we recognize the importance of
                    delivering solutions quickly to stay ahead of the
                    competition. Our services are designed to meet the
                    fast-paced needs of businesses, allowing them to make quick
                    decisions and stay ahead of the curve.
                  </p>
                </div>
              </div>
            </div>
            <div className="col col5">
              <div className="title" style={{ backgroundColor: "#fbbfce" }}>
                <span>Veracity</span>
              </div>

              <div className="slide-plate">
                <div className="spriteOne slide-number slide-act-one"></div>
                <div className="slide-detail">
                  <p>
                    The quality and accuracy of data is paramount to making
                    informed decisions. Our data engineering and analytics
                    services are designed to ensure the veracity of data,
                    allowing organizations to rely on the information they
                    receive.
                  </p>
                </div>
              </div>
            </div>
            <div className="col col6">
              <div className="title" style={{ backgroundColor: "#e7e2ff" }}>
                <span>Variability</span>
              </div>

              <div className="slide-plate">
                <div className="spriteOne slide-number slide-act-one"></div>
                <div className="slide-detail">
                  <p>
                    We understand that businesses change and evolve over time,
                    and our solutions are designed to accommodate variability.
                    Our services are flexible and customizable to meet the
                    changing needs of our clients, ensuring that they always
                    have access to the information and solutions they need to
                    succeed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
