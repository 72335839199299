import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Main from './Componants/Main/Main';
import ContactUs from './Componants/ContactUs/ContactUs';
import Service from './Componants/Services/Service';
import WebDevelopement from './Componants/WebDevelopement/WebDevelopement';
import FrontEndDevelopment from './FrontEndDevelopment/FrontEndDevelopment';
import AboutUs from './Componants/AboutUs/AboutUs';
import Consulting from './Componants/Consulting/Consulting';
import DataRelatedService from './Componants/DataRelatedService/DataRelatedService';
import BusinessIntelligence from './Componants/BusinessIntelligence/BusinessIntelligence';
import Careers from './Componants/Careers/Careers';


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Main />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/services" element={<Service />} />
          <Route path="/web-development" element={<WebDevelopement />} />
          <Route path="/consulting" element={<Consulting />} />
          <Route path="/service-data-related" element={<DataRelatedService />} />
          <Route path="/business-intelligence" element={<BusinessIntelligence />} />
          <Route path="/front-end-development" element={<FrontEndDevelopment />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/careers" element={<Careers />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
