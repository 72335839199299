import { useEffect } from "react";

import "./Consulting.scss";
import img12 from "../../Assets/images/img/web-dev.gif";
import blobSolidRedPink from "../../Assets/images/img/blob-solid-4-redpink.svg";
import blobDottedGreenBlue from "../../Assets/images/img/blob-dotted-5-greenblue-00B8A7.svg";
import blobHaikeiTopRight from "../../Assets/images/img/blob-haikei-2-purple.svg";
import blobHaikeiLeftBottom from "../../Assets/images/img/blob-haikei-3-blue-left-bottom.svg";

const Consulting = () => {

    useEffect(() => {
        document.title = "Consulting | Cephei Infotech";
    }, []);

    return (
        <div className="Consulting">
            <section className="web-dev-hero-section web-dev-row web-dev-container">
                <div className="web-dev-hero-text">
                    <h1>
                        <span>
                        Consulting
                        </span>
                    </h1>
                    <p>We specialize in providing comprehensive consulting services in the fields of software development and data analytics. Our team of experienced professionals combines technical expertise with strategic insights to help businesses unlock their full potential in the digital age.</p>
                    <a href="/contact" className="web-dev-hero-btn">Let's Connect</a>
                </div>
                <div className="web-dev-row web-dev-image__fade">
                    <img src={img12} alt=''></img>
                </div>
            </section>

            <img src={blobHaikeiTopRight} className="blob-haikei-top-right" alt=""></img>
            {/* <img src={blobHaikeiLeftBottom} className="blob-haikei-top-right" alt=""></img> */}
            {/* <div className="web-dev-quote-container">
                <img src={blobDottedGreenBlue} className="blob-dotted-left" alt=""></img>
                <div className="web-dev-quote-section">
                    <img src={blobSolidRedPink} className="blob-inside-quote-section" alt=""></img>
                    <h2>Lorem ipsum dolor</h2>
                    <div className="web-dev-quote-container-quote">
                        <p>

                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.

                        </p>
                    </div>
                </div>
            </div> */}

            <div className="service-container">
                <div className="row">
                <div className="col-md-4">
                    </div>
                    <div className="col-md-4">
                        <div className="main">
                            <div className="service">
                                <div className="service-logo">
                                    <i className='bx bx-code-alt'></i>
                                </div>
                                <h4>Consulting</h4>
                                <p className="content">Harness the power of technology with our tailored software development and data analytics consulting. From enhancing applications to optimizing processes, we guide you through the software development lifecycle. Unlock valuable insights and gain a competitive edge through our robust data analytics strategies and expertise in visualization, predictive analytics, and data-driven decision-making.</p>
                                {/* <a href="/contact" className="hero__button">Learn More</a> */}
                            </div>
                            <div className="shadow-one"></div>
                            <div className="shadow-two"></div>
                        </div>
                    </div>
                    {/* <div className="col-md-4">
                        <div className="main">
                            <div className="service">
                                <div className="service-logo">
                                    <i className='bx bx-code-curly'></i>
                                </div>
                                <h4>Back-End Development</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus nec commodo diam. Aenean eu commodo elit. Etiam vel magna congue elit eleifend tincidunt. Sed a fringilla magna, non pellentesque ex. Nullam faucibus tellus orci.</p>
                                <a href="#" className="hero__button">Learn More</a>
                            </div>
                            <div className="shadow-one"></div>
                            <div className="shadow-two"></div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="main">
                            <div className="service">
                                <div className="service-logo">
                                    <i className='bx bx-globe'></i>
                                </div>
                                <h4>Full Stack Development</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus nec commodo diam. Aenean eu commodo elit. Etiam vel magna congue elit eleifend tincidunt. Sed a fringilla magna, non pellentesque ex. Nullam faucibus tellus orci.</p>
                                <a href="#" className="hero__button">Learn More</a>
                            </div>
                            <div className="shadow-one"></div>
                            <div className="shadow-two"></div>
                        </div>
                    </div> */}

                </div>
            </div>
        </div>
    );

}

export default Consulting;