import "./HeroSection.scss";
import logo from "../../Assets/images/back_img.jpg";
import { useState,useEffect } from "react";

const HeroSection = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <section className="hero__section  hero-section-row hero-section-container hero-section-area">
        <div className="hero__text ">
          {/* <h1>Catalyze Your Digital Journey</h1> */}
          <h4>We Help Organizations To Grow Faster.</h4>
          <p>Got Curious about us?</p>
          {windowWidth > 768 ? (
            <div className="hero-section-text-container">
              "Our mission is to empower companies by providing comprehensive and
              innovative solutions that address their top to bottom needs and
              enhance connectivity. We strive to deliver exceptional data
              services, business intelligence, website development, and
              consulting services that drive growth and success for our clients."
            </div>
          ) : (
            <></>
          )}

          <a href="/contact" className="hero__button">
            Let's Connect
          </a>
        </div>
        {/* <div className="hero-section-row image__fade">
          <div className="pic" id="pic4" />
          <div className="pic" id="pic3" />
          <div className="pic" id="pic2" />
          <div className="pic" id="pic1" />
        </div> */}
      </section>
    </>
  );
};

export default HeroSection;
