import {  useState, useRef, useEffect } from "react";
import axios from "axios";
import "./ContactUsForm.js";
import { ClipLoader } from 'react-spinners';
// import ReCAPTCHA from "react-google-recaptcha";

const ContactUsForm = (props) => {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Company, setCompany] = useState("");
  // const [Service, setService] = useState("");
  const [Message, setMessage] = useState("");
  const [File, setFile] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef(null);

  // const captchaRef = useRef();

  useEffect(() => {
    document.title = "Contact Us | Cephei Infotech";
    axios.get(process.env.REACT_APP_serverURL)
    .then(res => {
      console.log(res.data);
    }
    )
    .catch(err => {
      console.log(err);
    }
    )
  }, []);

  const handleOnSubmit = (e) => {
    setIsSubmitting(true);
    if (!Name || !Email || !Company || !Message) {
      e.preventDefault();
      setIsSubmitting(false);
      alert("Please fill all the field");
      return;
    }

     e.preventDefault();

     

    const formData = new FormData();
    formData.append('name', Name);
    formData.append('email', Email);
    formData.append('website', Company);
    formData.append('service', selectedServices);
    formData.append('remarks', Message);
    formData.append('file', File);

      axios.post(process.env.REACT_APP_serverURL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
        console.log(res);
        alert("Your message has been sent successfully");
        setName("");
        setEmail("");
        setCompany("");
        setSelectedServices([]);
        setMessage("");
        setFile(null);
        fileInputRef.current.value = '';      
        setIsSubmitting(false);        
      })
      .catch(err => {
        console.log(err);
        alert("Something went wrong");
        setIsSubmitting(true);
      })

    // const token = captchaRef.current.getValue();
    // console.log(token);
    // captchaRef.current.reset();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="contact-us">
      <div className="contact-container">
        <div className="contact-content">
          <div className="contact-left-side">
            <div className="address details">
              <i className="bx bx-map"></i>
              <div className="topic">Ahmedabad, India</div>
            </div>
            <div className="phone details">
              <i className="bx bx-phone"></i>
              <div className="topic">+91-90331-03903</div>
            </div>
            <div className="email details">
              <i className="bx bx-envelope"></i>
              <div className="topic">connect@cepheiinfotech.com</div>
            </div>
          </div>
          <div className="right-side">
            <div className="topic-text">Send Us A Message</div>
            <form  onSubmit={handleOnSubmit}>
              <div className="input-box">
                <input
                  type="text"
                  value={Name}
                  placeholder="Enter Your Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="input-box">
                <input
                  type="text"
                  value={Email}
                  placeholder="Enter Your Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="input-box">
                <input
                  type="text"
                  value={Company}
                  placeholder="Company Name Or Website"
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                />
              </div>
              <div className="col-12" style={{ color: "#212529" }}>
                <label htmlFor="serviceRequired" className="form-label">
                  Which Service(s) Do You Require?
                </label>
                <div className="form-check">
                  <div className="form-check form-check-inline">
                    {/* <input className="form-check-input" type="checkbox" id="dataEngineeringCheck" value="option1" /> */}
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="dataEngineeringCheck"
                      value="Data Related Services"
                      checked={selectedServices.includes("Data Related Services")}
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedServices([
                              ...selectedServices,
                              e.target.value,
                            ])
                          : setSelectedServices(
                              selectedServices.filter(
                                (service) => service !== e.target.value
                              )
                            )
                      }
                    />
                    <label className="form-check-label" htmlFor="inlineCheckbox1">
                      Data Related Services
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    {/* <input className="form-check-input" type="checkbox" id="webDevelopementCheck" value="option2" /> */}
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="webDevelopementCheck"
                      value="Business Intelligence (BI) Services"
                      checked={selectedServices.includes("Business Intelligence (BI) Services")}
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedServices([
                              ...selectedServices,
                              e.target.value,
                            ])
                          : setSelectedServices(
                              selectedServices.filter(
                                (service) => service !== e.target.value
                              )
                            )
                      }
                    />
                    <label className="form-check-label" htmlFor="inlineCheckbox2">
                      Business Intelligence (BI) Services
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    {/* <input className="form-check-input" type="checkbox" id="ConsultingCheck" value="option3" /> */}
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="websiteDevelopmentCheck"
                      value="Website Development"
                      checked={selectedServices.includes("Website Development")}
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedServices([
                              ...selectedServices,
                              e.target.value,
                            ])
                          : setSelectedServices(
                              selectedServices.filter(
                                (service) => service !== e.target.value
                              )
                            )
                      }
                    />
                    <label className="form-check-label" htmlFor="inlineCheckbox3">
                      Website Development
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    {/* <input className="form-check-input" type="checkbox" id="ConsultingCheck" value="option3" /> */}

                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="consultingCheck"
                      value="Consulting"
                      checked={selectedServices.includes("Consulting")}
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedServices([
                              ...selectedServices,
                              e.target.value,
                            ])
                          : setSelectedServices(
                              selectedServices.filter(
                                (service) => service !== e.target.value
                              )
                            )
                      }
                    />
                    <label className="form-check-label" htmlFor="inlineCheckbox3">
                      Consulting
                    </label>
                  </div>
                </div>
              </div>
              <div className="input-box message-box">
                <textarea
                  placeholder="Describe Your Thoughts"
                  value={Message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="formFile" className="form-label">
                  Upload Document (If Any)
                </label>
                <input className="form-control" type="file" id="formFile" ref={fileInputRef} onChange={handleFileChange} />
              </div>
              {/* <div>
                <ReCAPTCHA
                  sitekey='6Le8khIiAAAAAFTrahC-eyGyMfZseLr9kwc-wrxq'
                  ref={captchaRef}
                />
              </div> */}
              <div className="button">
                <input type="submit" value="Send Now" className="button" />
              </div>
            </form>
            {isSubmitting && (
        <div>
          <ClipLoader size={50} color="#123abc" />
          <p>Submitting form...</p>
        </div>
      )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
