import { useEffect } from 'react';
import './Careers.scss';
import CareersForm from '../CareersForm/CareersForm';
import img12 from "../../Assets/images/img/de-3d-1-img.png";

const Careers = (props) => {

    useEffect(() => {
        document.title = "Contact Us | Cephei Infotech";
    }, []);

    return (
        <>
            <div className='top-hero-header'>
                <section className="contact-us-hero-section contact-us-row contact-us-container">
                    <div className="contact-us-hero-text">
                        <div className='banner-pattern'>
                            <div className="pattern_inner">
                                <span></span>
                                <div className="line line1"></div>
                                <div className="line line2"></div>
                                <div className="line line3"></div>
                            </div>
                        </div>
                        <h1>
                            <span>
                                Talk With Us
                            </span>
                        </h1>
                        <p>We aim to deliver you the world class business solutions as your digital companion. </p>
                    </div>
                    <div className='contact-us-hero-text'>
                    <div className="web-dev-row web-dev-image__fade careers-img">
          <img src={img12} alt=""></img>
        </div>
                    </div>
                </section>
            </div>

            <CareersForm />
        </>
    );
}

export default Careers;