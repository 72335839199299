import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
 import './TestimonialCarousel.css';
import testimonialMan from "../../Assets/images/Testimonial/Testimonial-1-man.webp";
import eris from "../../Assets/images/eris3.webp";
import astral from "../../Assets/images/astral.png";
import 'bootstrap/dist/css/bootstrap.min.css';


const testimonialsData = [
  // {
  //   id: 1,
  //   name: 'Zee 5',
  //   job: 'Quis Nostrud Exercitation',
  //   testimonial: `Our company provides comprehensive data services to ZEE5, a leading digital entertainment
  //   platform. We support ZEE5 in optimizing its content by sourcing and sorting traffic from
  //   different regions to gain insights into viewer behavior. Our data analysis includes identifying the
  //   type of content that is most popular and tracking viewing trends. Our team uses cutting-edge
  //   technology and advanced data science techniques to forecast future viewing patterns and gauge
  //   current activities on the platform. This information is invaluable in helping ZEE5 make informed
  //   decisions about the content they produce and distribute, ensuring that they remain at the forefront
  //   of the entertainment industry.`,
  //   image: testimonialMan
  // },
  {
    id: 2,
    name: 'Astral Pipes',
    job: '',
    testimonial: `We provide advanced data analytics services to Astral Pipes, a leading manufacturer of PVC
    pipes and fittings. We aim to support Astral Pipes in achieving optimal inventory management
    and sales performance. To achieve this, we work closely with the company to understand their
    inventory issues across India. By utilizing our renowned software and tools, we provide accurate
    sales and inventory forecasting, allowing the company to make informed decisions about
    production and distribution. Our data analysis goes beyond traditional methods and provides
    granular insights, giving Astral Pipes a competitive edge in the industry. Our services help the
    company achieve greater efficiency, improve customer satisfaction and drive growth.`,
    image: astral
  },
  {
    id: 3,
    name: 'Eris Pharma',
    job: '',
    testimonial: `Another one of our key clients is Eris Pharma, a leading pharmaceutical company. Our goal is to
    support Eris Pharma in optimizing their distribution network and ensuring the right medicines
    are in the right place at the right time. To achieve this, we work closely with the company to
    comprehensively understand their daily demand patterns for specific medicines in various
    regions. Utilizing innovative technologies, we provide real-time analysis of patient
    demographics, physician behavior, and disease trends, giving Eris Pharma a competitive edge in
    the industry. Our services offer deep insights, allowing the company to make informed choices
    and stay ahead of future trends. We aim to help Eris Pharma improve customer satisfaction and
    drive growth through enhanced distribution network optimization`,
    image: eris
  },
];

const TestimonialCarousel = () => {
  const [testimonialIndex, setTestimonialIndex] = useState(0);

  const handleTestimonialChange = () => {
    if (testimonialIndex === testimonialsData.length - 1) {
      setTestimonialIndex(0);
    } else {
      setTestimonialIndex(testimonialIndex + 1);
    }
  };

  return (
    <div className="testimonial-content-container">

    <Carousel activeIndex={testimonialIndex} onSelect={handleTestimonialChange}>
  {testimonialsData.map((testimonial) => (
    <Carousel.Item key={testimonial.id}>
      <section className="testimonial-container">
        <div className="testimonial">
          <div className="testimonial-content">
            <div className="slide">
              <img src={testimonial.image} alt="" className="testimonial-img" />
              <p>{testimonial.testimonial}</p>
              <i className='bx bxs-quote-alt-left quote-icon'></i>
              {/* <div className="testimonial-details">
                <span className="testimonial-name">{testimonial.name}</span>
                <span className="testimonial-position">{testimonial.job}</span>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </Carousel.Item>
  ))}
</Carousel>
</div>
  );
};

export default TestimonialCarousel;
