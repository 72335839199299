import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
 import './TestimonialCarouselForMobile.css';
import testimonialMan from "../../Assets/images/Testimonial/Testimonial-1-man.webp";
import eris from "../../Assets/images/eris3.webp";
import astral from "../../Assets/images/astral.webp";
import 'bootstrap/dist/css/bootstrap.min.css';


const testimonialsData = [
//   {
//     id: 1,
//     name: 'Zee 5',
//     job: 'Quis Nostrud Exercitation',
//     testimonial: `Our company provides comprehensive data services to ZEE5, a leading digital entertainment
//     platform. We support ZEE5 in optimizing its content by sourcing and sorting traffic from
//     different .`,
//     image: testimonialMan
//   },
  {
    id: 2,
    name: 'Astral Pipes',
    job: '',
    testimonial: `We provide Astral Pipes with advanced data analytics to optimize inventory and sales, driving efficiency and growth in the industry.`,
    image: astral
  },
  {
    id: 3,
    name: 'Eris Pharma',
    job: '',
    testimonial: `We assist Eris Pharma in optimizing their distribution network through real-time data analysis, enhancing competitiveness and customer satisfaction.`,
    image: eris
  },
];

const TestimonialCarouselForMobile = () => {
  const [testimonialIndex, setTestimonialIndex] = useState(0);

  const handleTestimonialChange = () => {
    if (testimonialIndex === testimonialsData.length - 1) {
      setTestimonialIndex(0);
    } else {
      setTestimonialIndex(testimonialIndex + 1);
    }
  };

  return (
    <div className="testimonial-content-container">

    <Carousel activeIndex={testimonialIndex} onSelect={handleTestimonialChange}>
  {testimonialsData.map((testimonial) => (
    <Carousel.Item key={testimonial.id}>
      <section className="testimonial-container">
        <div className="testimonial">
          <div className="testimonial-content">
            <div className="slide">
              <img src={testimonial.image} alt="" className="testimonial-img" />
              <p>{testimonial.testimonial}</p>
              <i className='bx bxs-quote-alt-left quote-icon'></i>
              {/* <div className="testimonial-details">
                <span className="testimonial-name">{testimonial.name}</span>
                <span className="testimonial-position">{testimonial.job}</span>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </Carousel.Item>
  ))}
</Carousel>
</div>


  );
};

export default TestimonialCarouselForMobile;
