import "./style.scss";
import { useState, useEffect } from "react";
import HeroSection from "../HeroSection/HeroSection";
import TestimonialCarousel from "../Carousel/TestimonialCarousel";
import TestimonialCarouselForMobile from "../Carousel/TestimonialCarouselForMobile";

import ConsultingImage from "../../Assets/images/Consulting.png";
import BIServicesImage from "../../Assets/images/img/bi.png";
import DataRelatedServicesImage from "../../Assets/images/img/data.png";
import WebsiteDevelopmentImage from "../../Assets/images/img/webimage.png";
import blobImageDottedPurple from "../../Assets/images/img/Blob-dotted-1.svg";
import blobImageSolidGreen from "../../Assets/images/img/blob-solid-2-green.svg";
import blobImageSolidPurple from "../../Assets/images/img/blob-solid-3-purple.svg";
import blobImageSolidRedPink from "../../Assets/images/img/blob-solid-3-redpink.svg";
import blobImageSolidOrange from "../../Assets/images/img/blob-solid-6-orange.svg";
import blobDoublegreen from "../../Assets/images/img/blob-double-1-028c6e.svg";
import doodleBackgroundPurple from "../../Assets/images/img/Doodle Backgrounds/doodly-background-1-832DD3.svg";
import testimonialMan from "../../Assets/images/Testimonial/Testimonial-1-man.webp";


const Main = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const serviceContainerOptions = [
    {
      strongTitle: 'Data',
      title: 'Related Services',
      blobImgSrc: blobImageSolidRedPink,
      style: {},
      // shortDescription: `Transform your data into a powerful asset with our comprehensive range of data services. Our
      // team of specialists will collaborate with you to provide tailored solutions that meet needs that
      // you not only anticipated, but also unlock valuable insights from large data sets to help you stay
      // ahead in the ever-evolving world of data. From data warehouse solutions to big data analytics
      // and data modeling, we have a proven track record of helping organizations effectively manage
      // and utilize their data. With our services, you'll be able to make informed decisions based on a
      // single source of truth and reach unanticipated career heights.`,
      shortDescription:'Unleash the power of your data with our comprehensive range of services. Our team collaborates with you to deliver tailored solutions, unlocking valuable insights from large datasets and keeping you ahead in the evolving data landscape. From data warehousing to big data analytics and modeling, we have a proven track record of effectively managing and utilizing organizational data. Our services provide a single source of truth, enabling informed decisions and propelling you to unanticipated career heights.',
      link: "/service-data-related",
      serviceImageSrc: DataRelatedServicesImage,
    },
    {
      strongTitle: 'Business',
      title: 'Intelligence (BI) Services',
      blobImgSrc: blobImageSolidGreen,
      style: {},
      // shortDescription: `Elevate your business with our comprehensive suite of business intelligence solutions. One of
      // our key offerings is Hawkeye, a powerful tool that provides ground-level Key Performance
      // Indicators (KPIs) tailored to the specific needs of people across different departments and levels.
      // Hawkeye provides easy-to-understand and interprets data, making it accessible to all. Our team
      // will help you churn your numbers to manufacture forecasts that will help secure your present and
      // future business goals. With our services, you'll be able to create interactive visualizations and
      // comprehensive reports, allowing you to gain a deeper understanding of your data and scale ahead
      // of all your competitors.`,
      shortDescription:'Elevate your business with our comprehensive business intelligence solutions. These solutions provide tailored KPIs for different departments and levels, making data accessible to all. Our team helps you generate forecasts and create interactive visualizations for deeper data understanding and a competitive advantage.',
      link: "/business-intelligence",
      serviceImageSrc: BIServicesImage,
    },
    {
      strongTitle: 'Website',
      title: 'Development',
      blobImgSrc: blobImageSolidOrange,
      style: { width: "35%", rotate: "45deg" },
      // shortDescription: `Enhance your online presence with our comprehensive suite of website development services.
      // Our offerings cover every aspect of the development process, from front-end to back-end and
      // full-stack development. Our team of skilled developers will transform your business problems
      // into design solutions that serve to achieve your objectives and help you stand out in the crowd.
      // From designing user-friendly and visually appealing interfaces to developing robust and scalable
      // back-end systems, our website development services will help you establish a strong online
      // presence and reach your target audience.`,
      shortDescription: 'Boost your online presence with our comprehensive website development services. Our skilled team covers front-end, back-end, and full-stack development, delivering design solutions that help you stand out. We create user-friendly interfaces and robust back-end systems, establishing a strong online presence to reach your target audience effectively.',
      link: "/web-development",
      serviceImageSrc: WebsiteDevelopmentImage,
    },
    {
      strongTitle: 'Consulting',
      title: '',
      blobImgSrc: blobImageSolidPurple,
      style: { width: "35%", rotate: "45deg" },
      shortDescription: `Maximize your business potential with our expert consulting services. Our advisors will partner
      with you to assess your challenges and solutions that address your unique requirements. Our
      offerings include strategy development, process improvement, organizational design, and change
      management, covering a range of essential areas. Whether you aim to improve operations,
      increase efficiency, or drive growth, our consulting services provide expert guidance to help you
      reach your objectives.`,
      link: "/consulting",
      serviceImageSrc: ConsultingImage,
    },

  ]

  return (
    <div className="main-container">

      <HeroSection />
      <ContactSummaryContainer />

      <div className="main-content-container">

        <div className="quote-container">
          <img src={blobDoublegreen} className="blob-page-background" alt=""></img>
          <img src={blobImageDottedPurple} className="blob-image-dotted" alt=""></img>
          {
            windowWidth > 768 ?
            <></>
            :
            <div className="quote-container-content">
            <div className="box-custom-icon-2">
              <i className='bx bxs-quote-left'></i>
            </div>
            <div className="quote-container-text">
              <p>
              {
            windowWidth > 768 ?
            (<></>):
            (<i>
              Our mission is to empower companies by providing comprehensive and innovative solutions
that address their top to bottom needs and enhance connectivity. We strive to deliver exceptional
data services, business intelligence, website development, and consulting services that drive
growth and success for our clients.
              </i>)
              }           
              </p>
            </div>
          </div>
          }
          
        </div>

        <div className="services-container">
          {
            windowWidth > 768 ?
            <div className="box-custom-icon-2">
              <i className='bx bxs-quote-left'></i>
            </div>
            :
            <></>
          }
        

          <div className="top-title">
            <div className="services-item-container">
              <h2 className="service-title">
                <span>
                  Let's See <br />What Services Do We Provide
                </span>
              </h2>
            </div>
          </div>
          {
            windowWidth > 768 ?
            (<>
              <ServiceContainer serviceContainerOptions={serviceContainerOptions} />
            </>):
            (<>     
              <ServiceContainerForMobile serviceContainerOptions={serviceContainerOptions}  />
            
            </>)
          }

        </div>

        {/* <div className="quote-container">
          <img src={doodleBackgroundPurple} className="doodle-background" alt=""></img>
        </div> */}

        <div>

        <div className="clients-container">
          <div className="top-title">
            <div className="container2">
              <h2 className="service-title">
                <span>
                  Our Clients
                </span>
              </h2>
            </div>
          </div>
        </div>

        {/* <section className="testimonial-container">
          <div className="testimonial">
            <div className="testimonial-content">
              <div className="slide">
                <img src={testimonialMan} alt="" className="testimonial-img"></img>
                <p>
                Our company provides comprehensive data services to ZEE5, a leading digital entertainment
platform. We support ZEE5 in optimizing its content by sourcing and sorting traffic from
different regions to gain insights into viewer behavior. Our data analysis includes identifying the
type of content that is most popular and tracking viewing trends. Our team uses cutting-edge
technology and advanced data science techniques to forecast future viewing patterns and gauge
current activities on the platform. This information is invaluable in helping ZEE5 make informed
decisions about the content they produce and distribute, ensuring that they remain at the forefront
of the entertainment industry.
                </p>
                <i className='bx bxs-quote-alt-left quote-icon'></i>
                <div className="testimonial-details">
                  <span className="testimonial-name">Zee 5</span>
                  <span className="testimonial-position">Quis Nostrud Exercitation</span>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {
            windowWidth > 768 ?
            (<>
              <TestimonialCarousel />
            </>):
            (<>     
              <TestimonialCarouselForMobile  />
            
            </>)
          }
        </div>
        
      </div>



    </div>
  );
}

export const ContactSummaryContainer = (props) => {
  return (
    <div className="contact-summary-container">
      <div className="contact-summary-container-item">
        <div className="box-custom-icon">
          <i className='bx bx-phone'></i>
        </div>
        <text className="" >+91-90331-03909</text>
      </div>
      <div className="contact-summary-container-item">
        <div className="box-custom-icon">
          <i className='bx bx-envelope'></i>
        </div>
        <text><a href="mailto:connect@cepheiinfotech.com"> connect@cepheiinfotech.com </a></text>
      </div>
      <div className="contact-summary-container-item">
        <div className="box-custom-icon">
          <i className='bx bxs-map-pin'></i>
        </div>
        <text> Ahmedabad, India</text>
      </div>
    </div>
  )
}

const ServiceContainer = (props) => {
  return (
    <>
     {
            props.serviceContainerOptions.map((option, index) => {
              if (index % 2 === 0) {
                return (
                  <div className="services" key={index}>
                    <div className="service-item">
                      <div className="container">
                        <div className="box">
                        <h3 className="title">
                            <strong>{option.strongTitle} </strong>
                            {option.title}
                          </h3>
                          {/* <img src={option.blobImgSrc} className="blob-image-right-center" style={option.style} alt=''></img> */}
                          <p className="short-description">
                            {option.shortDescription}
                          </p>
                          <div className="service-links">
                            <div className="service-btn">
                              <a className="btn" href={option.link}>Learn More</a>
                            </div>
                          </div>
                        </div>
                        <div className="box">
                        <div className="service-image">
                            <img src={option.serviceImageSrc} alt="Data Engineering Services" width={"400"}>
                            </img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="services" key={index}>
                    <div className="service-item">
                      <div className="container">
                        <div className="box">
                        <div className="service-image">
                            <img src={option.serviceImageSrc} alt="Data Engineering Services" width={"400"}>
                            </img>
                          </div>
                        </div>
                        <div className="box">
                        <h3 className="title">
                            <strong>{option.strongTitle} </strong>
                            {option.title}
                          </h3>
                          {/* <img src={option.blobImgSrc} className="blob-image-right-center" style={option.style} alt=''></img> */}
                          <p className="short-description">
                            {option.shortDescription}
                          </p>
                          <div className="service-links">
                            <div className="service-btn">
                              <a className="btn" href={option.link}>Learn More</a>
                            </div>
                          </div>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })}
    
    </>

  );
}

const ServiceContainerForMobile = (props) => {
  return (
    <>
    {
      props.serviceContainerOptions.map((option, index) => {
          return (
            <div className="services" key={index}>
              <div className="service-item">
                <div className="container">
                  <div className="box">
                  <h3 className="title">
                      <strong>{option.strongTitle} </strong>
                      {option.title}
                    </h3>
                    {/* <img src={option.blobImgSrc} className="blob-image-right-center" style={option.style} alt=''></img> */}
                    <p className="short-description">
                      {option.shortDescription}
                    </p>
                    <div className="service-links">
                      <div className="service-btn">
                        <a className="btn" href={option.link}>Learn More</a>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                  <div className="service-image">
                      <img src={option.serviceImageSrc} alt="Data Engineering Services" width={"350"}>
                      </img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ); 
      })
    }
    </>
  );
}

export default Main;
