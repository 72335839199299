import { useEffect } from "react";

import "./WebDevelopement.scss";
import img12 from "../../Assets/images/img/web-dev.gif";
import blobSolidRedPink from "../../Assets/images/img/blob-solid-4-redpink.svg";
import blobDottedGreenBlue from "../../Assets/images/img/blob-dotted-5-greenblue-00B8A7.svg";
import blobHaikeiTopRight from "../../Assets/images/img/blob-haikei-2-purple.svg";
import blobHaikeiLeftBottom from "../../Assets/images/img/blob-haikei-3-blue-left-bottom.svg";

const WebDevelopement = () => {
  useEffect(() => {
    document.title = "Web Development | Cephei Infotech";
  }, []);

  return (
    <div className="website-development">
      <section className="web-dev-hero-section web-dev-row web-dev-container">
        <div className="web-dev-hero-text">
          <h1>
            <span>Website Development</span>
          </h1>
          <p>
            We offer customized website development services that prioritize
            user experience and mobile responsiveness. Our expert team creates
            visually stunning websites that are optimized for search engines,
            ensuring your business stands out online and attracts valuable
            organic traffic.
          </p>
          <a href="/contact" className="web-dev-hero-btn">
            Let's Connect
          </a>
        </div>
        <div className="web-dev-row web-dev-image__fade">
          <img src={img12} alt=""></img>
        </div>
      </section>

      <img
        src={blobHaikeiTopRight}
        className="blob-haikei-top-right"
        alt=""
      ></img>
      {/* <img src={blobHaikeiLeftBottom} className="blob-haikei-top-right" alt=""></img> */}
      {/* <div className="web-dev-quote-container">
                <img src={blobDottedGreenBlue} className="blob-dotted-left" alt=""></img>
                <div className="web-dev-quote-section">
                    <img src={blobSolidRedPink} className="blob-inside-quote-section" alt=""></img>
                    <h2>Lorem ipsum dolor</h2>
                    <div className="web-dev-quote-container-quote">
                        <p>

                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.

                        </p>
                    </div>
                </div>
            </div> */}

      <div className="service-container">
        <div className="row">
          <div className="col-md-4">
            <div className="main">
              <div className="service">
                <div className="service-logo">
                  <i className="bx bx-code-alt"></i>
                </div>
                <h4>Front-End Development</h4>
                <p className="content">
                  We specialize in front-end development, leveraging HTML,
                  CSS, and JavaScript frameworks like React and Angular to
                  create immersive and responsive user interfaces. Our focus on
                  user experience, cross-browser compatibility, and performance
                  optimization ensures seamless integration with back-end
                  systems. Trust us for professional front-end solutions that
                  enhance your digital presence and engage your audience
                  effectively.
                </p>
                {/* <a href="/front-end-development" className="hero__button">Learn More</a> */}
              </div>
              <div className="shadow-one"></div>
              <div className="shadow-two"></div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="main">
              <div className="service">
                <div className="service-logo">
                  <i className="bx bx-code-curly"></i>
                </div>
                <h4>Back-End Development</h4>
                <p className="content content-space">
                  Leveraging our expertise in server-side languages such as
                  Python, Java, and Node.js, we specialize in developing robust
                  backend systems with seamless database integration and secure
                  API development. Our focus on performance optimization,
                  scalability, and seamless third-party integrations ensures
                  reliable and efficient web applications. Trust us for
                  professional backend development services that elevate your
                  online presence.
                </p>
                {/* <a href="#" className="hero__button">Learn More</a> */}
              </div>
              <div className="shadow-one"></div>
              <div className="shadow-two"></div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="main">
              <div className="service">
                <div className="service-logo">
                  <i className="bx bx-globe"></i>
                </div>
                <h4>Full Stack Development</h4>
                <p className="content">
                With our expertise in front-end and back-end technologies, we ensure seamless integration and exceptional user experiences, making us your ideal partner for full stack development that transforms your ideas into reality.Our solutions encompass database management, API development, DevOps, and project management, delivering scalable and user-friendly applications. Partner with us for end-to-end full stack development that brings your ideas to life.
                </p>
                {/* <a href="#" className="hero__button">Learn More</a> */}
              </div>
              <div className="shadow-one"></div>
              <div className="shadow-two"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="web-dev-container">
                <div className="web-developement-header">
                    <div className="web-developement-header-left">
                        <h1>Web Developement</h1>
                    </div>
                    <div className="web-developement-right-div">
                        <img src={WebDevelopementImage} />
                    </div>
                </div>
            </div> */}
    </div>
  );
};

export default WebDevelopement;
