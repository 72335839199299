// import { PropaneSharp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './Navbar.scss';
// import '../../../public/favicon.ico'


const Navbar = (props) => {
    //const navigate = useNavigate();

    return (
        <>
            <div className="nav-container">
                <nav className="navbar navbar-expand-xl shadow-sm p-3 mb-5 bg-white rounded py-2">
                    <div className="container-fluid">
                        <div className="nav-item nav-cursor" onClick={() => { console.log('Clicked!!'); }}>
                            <a className="navbar-brand" href='/'>
                                <img src='../../Assets/images/2.jpg' width="30" height="30" alt=""></img>
                            </a>
                        </div>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav justify-content-center">
                                <li className="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Services
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown" >
                                        <ul>
                                            <li><a className="dropdown-item" href="/services">Data Engineering</a></li>
                                            <li><a className="dropdown-item" href="/services">Open Stack</a></li>
                                            <li><a className="dropdown-item" href="/services">Azure Stack</a></li>
                                            <li><a className="dropdown-item" href="/services">ETL</a></li>
                                        </ul>
                                        <ul>
                                            <li><a className="dropdown-item" href="/services">Web Developement</a></li>
                                            <li><a className="dropdown-item" href="/services">Frontend Developement</a></li>
                                            <li><a className="dropdown-item" href="/services">Backend Developement</a></li>
                                            <li><a className="dropdown-item" href="/services">Full Stack Developement</a></li>
                                        </ul>
                                        <ul>
                                            <li><a className="dropdown-item" href="/services">Consulting</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Why Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Opportunities</a>
                                </li>
                                <li
                                    className="nav-item nav-cursor"
                                    onClick={() => {
                                        //executeScroll(myRef3);
                                    }}
                                >
                                    <a className="nav-link">About us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/contact">Let's Connect</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );


}

export default Navbar;