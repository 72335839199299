import "./Footer.scss";
import { MdEmail, MdOutlineDoubleArrow } from "react-icons/md";
import { IoIosCall } from "react-icons/io";

function Footer(props) {
  return (
    <>
      <footer className="footer-distributed">
        <div className="footer-left">
          <h3>Cephei Infotech Pvt. Ltd.</h3>
          <p className="footer-links">
            <a href="/">Home</a>
            |
            <a href="/about-us">About Us</a>
            |
            <a href="/careers">Careers</a>
            |
            <a href="/contact">Contact Us</a>
          </p>
          <p className="footer-company-name"> Copy Right 2022 <strong>Cephei Infotech Pvt. Ltd.</strong> All Rights Are Reserved.</p>
        </div>

        <div className="footer-center">
          {/* <div className="info-summary-item">
            <div className="info__icon"> */}
          <div>
            <i className='bx bxs-map-pin'></i>
            <p><span>Ahmedabad, </span>India</p>
          </div>
          {/* </div> */}
          {/* </div> */}
          {/* <div className="info-summary-item">
            <div className="info__icon"> */}
          <div>
            <i className='bx bx-phone'></i>
            <p>+91-90331-03909</p>

          </div>
          {/* </div> */}
          {/* </div> */}
          {/* <div className="info-summary-item">
            <div className="info__icon"> */}
          <div>
            <i className='bx bx-envelope'></i>
            <p>connect@cepheiinfotech.com</p>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>

        <div className="footer-right">
          <p className="footer-company-about">
            <span>About The Company </span>
            <strong>Cephei Infotech Pvt. Ltd. </strong>Empowering businesses with innovative solutions, we are a leading provider of technology services that drive growth and success. Trust our expertise to transform your ideas into reality and propel your business forward.
          </p>
          <div className="footer-icons">
            <a href="https://www.linkedin.com/company/cephei-infotech/"><i className='bx bxl-linkedin-square'></i></a>
            <a href="https://wa.me/+919033103909"><i className='bx bxl-whatsapp' ></i></a>

          </div>
        </div>
      </footer>
      {/* <div className="bottom-container">
        <div className="bottom-container-left">
          <i>Cephei Infotech Pvt. Ltd.</i>
          <p style={{fontSize:"15px"}}>All Rights Are Reserved.</p>
        </div>
        <div className="bottom-container-center">
          <h4><i>Important Links</i></h4>
          <h5>
            <MdOutlineDoubleArrow />
            &nbsp;Home
          </h5>
          <h5>
            <MdOutlineDoubleArrow />
            &nbsp;Carrers
          </h5>
          <h5>
            <MdOutlineDoubleArrow />
            &nbsp;About us
          </h5>
        </div>
        <div className="bottom-container-right">
          <h4><i>Get in touch</i></h4>
          <h5>
            <MdEmail />
            &nbsp; info@cepheiinfotech.com{" "}
          </h5>
          <h5>
            <IoIosCall />
            &nbsp; +91 98989 98989{" "}
          </h5>
        </div>
      </div> */}
    </>
  );
}

export default Footer;