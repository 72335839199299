import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataWarehouse from "../../Assets/images/Data_Warehousing.jpg";
import "./Service.scss";


function Service() {

  useEffect(()=>{
    document.title = "Services | Cephei Infotech"
  }, [])
  const navigate = useNavigate();

  const handleOnClick = (event) => {
    navigate('/contact');
  }
  return (
    <>
      <div className="Services">
        <div className="container1">
          <h1><i>How can we help you?</i></h1>
        </div>
        <div className="container2">
          <div className="underline">_________________________</div>
        </div>
        <div className="service-container-text">

          Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in
          massa egestas mollis varius; dignissim elementum. Mollis tincidunt
          mattis hendrerit dolor eros enim, nisi ligula ornare.
        </div>
        <div className="left-image-container">
          <div className="left-image">
            <img src={DataWarehouse} alt="..." />
          </div>

          <div className="image-container-text">
            <div className="image-container-text-heading">
              <i>
                Data Warehouse Implemantation
              </i>
            </div>
            <div className="image-container-text-data">
              Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in
              massa egestas mollis varius; dignissim elementum. Mollis
              tincidunt mattis hendrerit dolor eros enim, nisi ligula ornare.
              Hendrerit parturient habitant pharetra rutrum gravida porttitor
              eros feugiat. Mollis elit sodales taciti duis praesent id.
              Consequat urna vitae morbi nunc congue.
              Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in
              massa egestas mollis varius; dignissim elementum. Mollis
              tincidunt mattis hendrerit dolor eros enim, nisi ligula ornare.
              Hendrerit parturient habitant pharetra rutrum gravida porttitor
              eros feugiat.
            </div>
            <button type="button" class="btn btn-link" onClick={handleOnClick}>Let's Connect</button>
          </div>
        </div>
      </div>

    </>
  );
}

export default Service;